import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getAuth, onAuthStateChanged } from './firebase.js' 

import NavToolbar from './pages/toolbar';
import Home from './pages/home';
import Contact from './pages/contact';
import Login from './pages/login'
import ResourceForm from './pages/resourceForm.js'
import ResourceTab from './pages/resourcetab';
import Footer from './pages/footer.js'

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
        },
      },
    },
  },
});

function App() {
  const [user, setUser] = useState(null)
  const helmetContext = {};
  
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user.uid);
      } else {
        setUser(null)
      }
    })
  }, [user])
  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100vw', overflowX: 'hidden' }}>
            <Box sx={{height: "17.5vh"}}>
              <NavToolbar isAuthenticated={user ? true : false}/>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, width: '100%' }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                {user && <Route path="/resource-form" element={<ResourceForm/>}/>}
                <Route path="/login" element={<Login isAuthenticated={user === null ? false : true}/>}/>
                <Route path="/resourcestab/*" element={<ResourceTab/>} />
              </Routes>
            </Box>
            <Box>
              <Footer/>
            </Box>
          </Box>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
