import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from '../firebase.js';
import { Box, Typography, Container, Card, CardContent, CardActions, TextField, Button, InputAdornment, OutlinedInput, IconButton, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';

const CenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: "center",
  height: 'calc(100vh - 64px)',
  width: '100%',
  padding: '0 16px',
});

function Login(isAuthenticated) {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleSignIn = () => {
    const { email, password } = formData;
    const auth = getAuth();

    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .then((userCredential) => {
        navigate("/resource-form");
      })
      .catch((error) => {
        console.error("Error signing in", error);
        setInvalidLogin(true);
      });
  };

  return (
    !isAuthenticated.isAuthenticated ? (<CenteredContainer>
        <Card sx={ { border : 5 , borderColor : "#D6F1FF", borderRadius : 10 } }>
          <CardContent>
            <Typography sx={{ mt: "2vh", mx: "5vw" }} variant="h4" gutterBottom>
              Administrator Login
            </Typography>
            <Typography sx={{ textAlign: "center", width: "100%" }} variant="h6" gutterBottom style={{ color: "red" }}>
              {invalidLogin ? <Typography>Invalid Email/Password</Typography> : null}
            </Typography>
            <Typography sx={{ mx: "3vw", mt: "2vh"}} variant="h6" gutterBottom>
              Email
            </Typography>
            <Box sx={{display: "flex", justifyContent: "center"}}>
              <TextField
                sx={{width: "80%" }}
                id="email"
                variant="outlined"
                value={formData["email"]}
                onChange={handleChange}
                name="email"
              />
            </Box>
            <Typography sx={{ mx: "3vw", mt: "2vh" }} variant="h6" gutterBottom>
              Password
            </Typography>
            <Box sx={{display:"flex", justifyContent: "center"}}>
              <FormControl sx={{ width: "80%" }} variant="filled">
                <OutlinedInput
                  id="filled-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={showPassword ? 'hide the password' : 'display the password'}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        { showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions sx={{display: "flex", justifyContent: "center"}}>
            <Button onClick={handleSignIn} variant="contained" sx={{width: "80%", textTransform: "none", mb: "3vh", fontSize: "18px" }}>Login</Button>
          </CardActions>
        </Card>
      </CenteredContainer>) : (<CenteredContainer><Typography fontSize="32px" marginTop="32px">You are already signed in as an administrator</Typography></CenteredContainer>)

  ); 
}

export default Login;
