import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Modal, MenuItem, Select, ListItemText, TextField, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet-async';

import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'; //Filter icon

import LocalDiningOutlinedIcon from '@mui/icons-material/LocalDiningOutlined'; //food
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'; //shelter 
import Theme from "../components/theme"
import {isOpen} from "../utils/times";
import FilteredResourceCard from "../components/FilteredResourceCard";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85vw', // Reduced width for mobile
  maxWidth: '500px',
  bgcolor: 'white',
  borderRadius: '10px',
  boxShadow: 24,
  p: 3,
  textAlign: 'center',
};

const FilterButton = styled(Button)(({ theme, isSelected }) => ({
  width: "240px",
  height: "7vh",
  paddingRight: "1vw",
  paddingLeft: "1vw",
  borderRadius: "3.5vh",
  fontSize: "24px",
  textTransform: "none",
  backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.secondary.main,
  color: isSelected ? "white" : "black",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: isSelected ? theme.palette.primary.dark : theme.palette.secondary.main,
    boxShadow: "none",
  },
}));


function ResourcesPage(props) {
  const [loading, setLoading] = useState(true);
  const [selectedResourceType, setSelectedResourceType] = useState("All");
  const [open, setOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState("All");
  const [selectedTime, setSelectedTime] = useState("");
  const [inputDay, setInputDay] = useState("All");
  const [inputTime, setInputTime] = useState("");
  const [inputResourceType, setInputResourceType] = useState("All");
  const [filteredResources, setFilteredResources] = useState([]);

  const days = ["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const resourceTypes = ["All", "Food Pantry", "Shelter", "Medical", "Financial"]

  useEffect(() => {
    setFilteredResources(props.resources);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the delay as needed
    return () => clearTimeout(timer);
  }, [props.resources])
  
  useEffect(() => {
    // Simulate loading time
    setInputResourceType(selectedResourceType);
    setInputDay(selectedDay);
    setInputTime(selectedTime);
    filterResources();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResourceType, selectedDay, selectedTime]);

  function handleFilter(filterApplied){
    if(!filterApplied && !selectedDay){
      setInputDay("All")
      setInputTime("")
    } else if (filterApplied){
      setSelectedResourceType(inputResourceType);
      setSelectedDay(inputDay);
      setSelectedTime(inputTime);
      filterResources();
    }
    setOpen(false);
  }

  function filterResources() {
    if (selectedDay === "All" && selectedTime === "" && (selectedResourceType === null || selectedResourceType === "All")) {
      setFilteredResources(props.resources);
      return;
    }

    const newFiltered = [];
    props.resources.forEach((resource) => {
      if((selectedResourceType !== null && selectedResourceType !== "All" && resource.type !== selectedResourceType) ||
         (selectedResourceType === "Other" && resource.type === "Food Pantry" && resource.type === "Shelter")){
        console.log("resource type rejection")
        return;
      }
      console.log(resource.id)
      if(selectedTime && selectedDay !== "All" && isOpen(selectedTime, selectedDay, resource.openTimes)){
        newFiltered.push(resource);
      }
    })
    setFilteredResources(newFiltered);
  }

  return (
    <>
    <Helmet>
      <title>Homeless Resources - Food, Shelter, Medical & Financial Help</title>
      <meta name="description" content="Find essential resources including food pantries, shelters, and other services available near you." />
    </Helmet>

    <Theme>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "83vh"}}>
          <CircularProgress />
        </Box>
      ) : (
        <Box textAlign="center" width="100%">
          <Typography color="primary" variant="h3">Resources</Typography>
          <Typography color="primary" variant="h5" padding="3vh">Click on any of the resources below for more info</Typography>
          <Box 
            sx={{ 
              display: "flex", 
              justifyContent: "center", 
              marginTop: "4vh", 
              marginX: "10vw", 
              gap: "1vw", // Adjust gap as needed
              flexDirection: { xs: "column", sm: "row" }, 
              flexWrap: { xs: "wrap", sm: "nowrap" }  // Stack buttons on small screens
            }}
          >
            <Box sx={{ width: "100%", height: "50px", mb: "1vh", display: "flex", justifyContent: "center"}}>
              <FilterButton key="misc" variant="contained" onClick={() => setOpen(true)}>
                <TuneOutlinedIcon sx={{ marginRight: "5px" }} fontSize="large"/>
                  Filter
              </FilterButton>
              {(selectedDay !== "All" || selectedTime !== "") ? <Button onClick={() => {setSelectedDay("All"); setSelectedTime(""); setInputDay("All"); setInputTime("")}} >X</Button> : null}
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "20vw" }, height: "50px", mb: "1vh", display: "flex", justifyContent: "center" }}>
              <FilterButton key="all" variant="contained" onClick={() => {setSelectedResourceType("All")}} isSelected={selectedResourceType==="All"}>All</FilterButton>
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "20vw" }, height: "50px", mb: "1vh", display: "flex", justifyContent: "center" }}>
              <FilterButton key="food" variant="contained" onClick={() => {setSelectedResourceType(resourceTypes[1])}} isSelected={selectedResourceType===resourceTypes[1]}>
                <LocalDiningOutlinedIcon sx={{ marginRight: "5px", alignItems: "center" }} fontSize="large" />
                {resourceTypes[1]}
              </FilterButton>
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "20vw" }, height: "50px", mb: "1vh", display: "flex", justifyContent: "center" }}>
              <FilterButton key="shelter" variant="contained" onClick={() => {setSelectedResourceType(resourceTypes[2])}} isSelected={selectedResourceType===resourceTypes[2]}>
                <HomeOutlinedIcon sx={{ marginRight: "5px", alignItems: "center" }} fontSize="large" />
                {resourceTypes[2]}
              </FilterButton>
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "20vw" }, height: "50px", mb: "1vh", display: "flex", justifyContent: "center" }}>
              <FilterButton key="shelter" variant="contained" onClick={() => {setSelectedResourceType("Other")}} isSelected={selectedResourceType==="Other"}>
                <HomeOutlinedIcon sx={{ marginRight: "5px", alignItems: "center" }} fontSize="large" />
                Other
              </FilterButton>
            </Box>  
          </Box> 
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", gap: "2vw", marginX: "5vw" }}>
            {filteredResources.length !== 0 ?
            filteredResources.map((location) => (
                <FilteredResourceCard location={location} selectedDay={selectedDay}/>
            )) : <Typography variant="h5" sx={{mt: "50px"}}>No Available Resources</Typography>}
          </Box>
        </Box>
      )}
      <Modal open={open} onClose={() => handleFilter(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h5" color="primary" gutterBottom mb="20px">
            Filter Resources
          </Typography>
          <InputLabel id="day-of-week-label">Resource Type</InputLabel>
          <Select
            value={inputResourceType}
            onChange={(event) => setInputResourceType(event.target.value)}
            sx={{ width: '100%', mb: 2 }}
          >
            {resourceTypes.map((type) => (
              <MenuItem key={type} value={type}>
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select>
          <InputLabel id="day-of-week-label">Day of Week</InputLabel>
          <Select
            value={inputDay}
            onChange={(event) => setInputDay(event.target.value)}
            sx={{ width: '100%', mb: 2 }}
          >
            {days.map((day) => (
              <MenuItem key={day} value={day}>
                <ListItemText primary={day} />
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            label="Chosen Time"
            type="time"
            value={(selectedTime !== "" && selectedTime === inputTime) ? selectedTime : inputTime}
            onChange={(event) => setInputTime(event.target.value)}
            inputProps={{ step: 900 }}
            InputLabelProps={{ shrink: true }}
            required
            disabled={inputDay === "All"}
          >

          </TextField>
          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={() => handleFilter(true)}>
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Modal>
    </Theme>
    </>
  );
}

export default ResourcesPage;