import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Select, MenuItem, FormControl, Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import { doc, getDoc, setDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import { firestore } from '../firebase';

const ResourceForm = () => {
  const [mode, setMode] = useState('');
  const [resourceId, setResourceId] = useState('');
  const [resources, setResources] = useState([]);
  const [closed, setClosed] = useState(Array(7).fill(true));
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    openTimes: Array(7).fill("Closed"),
    overview: '',
    services: '',
    type: '',
    email: '',
    phone: '',
    website: '',
  });
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const types = ["Food Pantry", "Shelter", "Medical", "Financial"];

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'resources'));
        const resourceList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResources(resourceList);
      } catch (error) {
        console.error("Error fetching resources: ", error);
      }
    };

    fetchResources();
  }, []);

  useEffect(() => {
    setClosed(formData.openTimes.map((e) => e === "Closed" ? true : false));
  }, [formData.openTimes]);

  useEffect(() => {
    const fetchResource = async () => {
      if (mode === 'edit' && resourceId) {
        try {
          const resourceRef = doc(firestore, 'resources', resourceId);
          const resourceSnap = await getDoc(resourceRef);

          if (resourceSnap.exists()) {
            setFormData(resourceSnap.data());
          } else {
            alert("No such resource found!");
          }
        } catch (error) {
          console.error("Error fetching resource: ", error);
        }
      }
    };

    fetchResource();
  }, [mode, resourceId]);

  const handleModeChange = (event) => {
    setMode(event.target.value);
    setFormData({
      name: '',
      address: '',
      openTimes: Array(7).fill("Closed"),
      overview: '',
      services: '',
      type: '',
      email: '',
      phone: '',
      website: '',
    });
    setClosed(Array(7).fill(true));
    setResourceId('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const parseTime = (time12) => {
    if (time12 === "" || time12 === "Closed") return "";
    if(time12 === "00:00") return time12;
    const [time, modifier] = time12.split(' ');
    let [hour, minute] = time.split(':').map(Number);
  
    if (modifier.toLowerCase() === 'pm' && hour !== 12) {
        hour += 12;
    } else if (modifier.toLowerCase() === 'am' && hour === 12) {
        hour = 0;
    }
    let time24 = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    return time24 ;
  };

  const handleOpenTimesChange = (index, startOrEnd, dayjs) => {
    const updatedOpenTimes = [...formData.openTimes];
    
      if(dayjs === null) {
        updatedOpenTimes[index] = "Closed"
      }
      else {
        let [start, end] = updatedOpenTimes[index].split(' - ');
        if (start === "Closed") {
          start = "12:00 AM"
        }
        if (end === undefined) {
          end = "12:00 AM"
        }
        updatedOpenTimes[index] = startOrEnd === 'start' ? `${dayjs.format('h:mm A')} - ${end}` : `${start} - ${dayjs.format('h:mm A')}`;
      }
      setFormData((prevData) => ({ ...prevData, openTimes: updatedOpenTimes }));
      console.log(formData.openTimes);
      console.log(updatedOpenTimes);
  
  };

  const handleClosedChange = (index, isClosed) => {
    setClosed(closed.map((e, i) => { return i === index ? isClosed : e}));
  };

  const camelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => index === 0 ? match.toLowerCase() : match.toUpperCase())
      .replace(/\s+/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedOpenTimes = formData.openTimes.map(time => {
        if (time === "Closed") return "Closed";
        const [start, end] = time.split(' - ');
        return start === "00:00" && end === "00:00"
          ? "Closed"
          : `${start} - ${end}`;
    })

    const dataToSave = { ...formData, openTimes: formattedOpenTimes };
    const resourceDocId = camelCase(formData.name); // Use the camel case resource name as the ID

    try {
      if (mode === 'edit') {
        await setDoc(doc(firestore, "resources", resourceId), dataToSave);
        alert("Resource updated successfully!");
      } else {
        await setDoc(doc(firestore, "resources", resourceDocId), dataToSave); // Use the camel case ID
        alert("Resource added successfully!");
      }

      setFormData({
        name: '',
        address: '',
        openTimes: Array(7).fill(("Closed")),
        overview: '',
        services: '',
        type: '',
        email: '',
        phone: '',
        website: '',

      });
      setClosed(Array(7).fill(true));
      setMode('');
      setResourceId('');
    } catch (error) {
      console.error("Error saving resource: ", error);
      alert("Failed to save resource. Please try again.");
    }
  };
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this resource?")) {
      try {
        await deleteDoc(doc(firestore, 'resources', resourceId));
        alert("Resource deleted successfully!");
        setFormData({
          name: '',
          address: '',
          openTimes: Array(7).fill("Closed"),
          overview: '',
          services: '',
          type: '',
          email: '',
          phone: '',
          website: '',
        });
        setMode('');
        setResourceId('');
      } catch (error) {
        console.error("Error deleting resource: ", error);
        alert("Failed to delete resource. Please try again.");
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Resource Management
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please select whether you'd like to add a new resource or edit an existing one.
        </Typography>

        <FormControl fullWidth margin="normal">
          <Select 
            value={mode} 
            onChange={handleModeChange} 
            displayEmpty
            renderValue={() => {
              if (mode === "") {
                return <Typography>Select Mode</Typography>;
              }
              if(mode === 'add'){
                return <Typography>Add New Resource</Typography>
              }
              return <Typography>Edit Existing Resource</Typography>
            }}
          >
            <MenuItem value="add">Add New Resource</MenuItem>
            <MenuItem value="edit">Edit Existing Resource</MenuItem>
          </Select>
        </FormControl>

        {mode === 'edit' && (
          <FormControl fullWidth margin="normal">
            <Select 
              value={resourceId} 
              onChange={(e) => setResourceId(e.target.value)} 
              disabled={!resources.length}
              displayEmpty
              renderValue={() => {
                if (resourceId === '') {
                  return <Typography>Select Resource to Edit</Typography>;
                }
                return formData.name;
              }}
            >
              {resources.map((resource) => (
                <MenuItem key={resource.id} value={resource.id}>{resource.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {mode && (
          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              name="name"
              value={formData.name}
              onChange={handleChange}
              label="Resource Name"
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 50,
                  },
                },
              }}
              helperText={`${formData.name.length}/50 characters`}
              required
            />
            <TextField
              name="address"
              value={formData.address}
              onChange={handleChange}
              label="Address"
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 100,
                  },
                },
              }}
              helperText={`${formData.address.length}/100 characters`}
              required
            />
            <TextField
              label="Phone"
              value={formData.phone}
              onChange={(e) => {
                const phoneValue = e.target.value;
                setFormData({ ...formData, phone: phoneValue });
                if (phoneValue === '') {
                  setPhoneError(''); // Clear error if empty
                } else {
                  const phoneRegex = /^[0-9]+$/;
                  if (!phoneRegex.test(phoneValue)) {
                    setPhoneError('Invalid phone number format');
                  } else {
                    setPhoneError('');
                  }
                }
              }}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 15,
                  },
                },
              }}
              helperText={phoneError || `${formData.phone.length}/15 characters`}
              error={!!phoneError}
            />
            <TextField
              label="Email"
              value={formData.email}
              onChange={(e) => {
                const emailValue = e.target.value;
                setFormData({ ...formData, email: emailValue });
                if (emailValue === '') {
                  setEmailError('');
                } else {
                  // eslint-disable-next-line no-useless-escape
                  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (!emailRegex.test(emailValue)) {
                    setEmailError('Invalid email format');
                  } else {
                    setEmailError('');
                  }
                }
              }}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 50,
                  },
                },
              }}
              helperText={emailError || `${formData.email.length}/100 characters`}
              error={!!emailError}
            />
            <TextField
              name="website"
              value={formData.website}
              onChange={handleChange}
              label="Website Link"
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start"></InputAdornment>,
                  inputProps: {
                    maxLength: 100,
                  },
                },
              }}
              helperText={`${formData.website.length}/100 characters`}
              />
            <TextField
              name="overview"
              value={formData.overview}
              onChange={handleChange}
              label="Overview"
              multiline
              rows={4}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              }}
              helperText={`${formData.overview.length}/500 characters`}
              required
            />
            <TextField
              name="services"
              value={formData.services}
              onChange={handleChange}
              label="Services Offered"
              multiline
              rows={4}
              slotProps={{
                input: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              }}
              helperText={`${formData.services.length}/500 characters`}
              required
            />
            
            <FormControl fullWidth margin="normal">
              <Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
                displayEmpty
                renderValue={() => {
                  if (formData.type === "") {
                    return <Typography>Resource Type</Typography>;
                  }
                  return <Typography>{formData.type}</Typography>;
                }}
              >
                {types.map((type, index) => (
                  <MenuItem key={index} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {daysOfWeek.map((day, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked = {closed[index]}
                      onChange={(e) => handleClosedChange(index, e.target.checked)}
                    />
                  }
                  label="Closed"
                />
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                  label={`Start Time`}
                  value={(closed[index] !== null && closed[index]) ? null : formData.openTimes[index] === "Closed" ? dayjs(dayjs().format("YYYY-MM-DD") + "T00:00:00") : dayjs(dayjs().format("YYYY-MM-DD") + "T" + parseTime(formData.openTimes[index].split('-')[0].trim()))}
                  onChange={ (value) => handleOpenTimesChange(index, 'start', value)}
                  disabled={closed[index]}
                  required
                  fullWidth
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                  label={'End Time'}
                  value={(closed[index] !== null && closed[index]) ? null : formData.openTimes[index] === "Closed" ? dayjs(dayjs().format("YYYY-MM-DD") + "T00:00:00") : dayjs(dayjs().format("YYYY-MM-DD") + "T" + parseTime(formData.openTimes[index].split('-')[1].trim()))}
                  onChange={ (e) => handleOpenTimesChange(index, 'end', e)}
                  disabled={closed.some(e => e === undefined) ? true : closed[index]}
                  required
                  fullWidth
                  />
                </LocalizationProvider>
                
                
              </Box>
            ))}
                <Button type="submit" variant="contained">
    {mode === 'edit' ? "Save Changes" : "Add Resource"}
  </Button>

  
  {/* Show Delete Button only if mode is "edit" and a resource is selected */}
  {mode === 'edit' && resourceId && (
    <Button
      variant="outlined"
      color="error"
      onClick={handleDelete}
      sx={{ marginTop: 2 }}
    >
      Delete Resource
    </Button>
  )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ResourceForm;
