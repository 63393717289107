import {Card, CardActionArea, CardContent, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import LocalDiningOutlinedIcon from "@mui/icons-material/LocalDiningOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import React from "react";
import {styled} from "@mui/system";

const StyledCard = styled(Card)({
    marginTop: '5vh',
    width: "18vw",
    minWidth: "300px",
    backgroundColor: '#DEE5ED',
    borderRadius: 10
});
export default function FilteredResourceCard({location, selectedDay}) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const parseTime = (timeStr) => {
        if (!timeStr) return 0;
        const [time, period] = timeStr.split(" ");
        let [hours, minutes] = time.split(":").map(Number);
        if (period === "pm" && hours !== 12) hours += 12;
        if (period === "am" && hours === 12) hours = 0;
        return hours * 60 + (minutes || 0);
    };

    const getDisplayDayAndHours = (selectedDay, location) => {
        const selectedIndex = days.indexOf(selectedDay);
        const prevIndex = (selectedIndex - 1 + days.length) % days.length;

        let displayDay = selectedDay;
        let displayHours = location.openTimes[selectedIndex];
        if (location.openTimes[prevIndex] !== "Closed") {
            const [, endStr] = location.openTimes[prevIndex].split(" - ");
            const endTime = parseTime(endStr);

            if (endTime < parseTime("7:01 am")) {
                displayDay = days[prevIndex];
                displayHours = location.openTimes[prevIndex];
            }
        }

        return { displayDay, displayHours };
    };
    return (
        <StyledCard key={location.id}>
            <CardActionArea
                component={Link}
                to={`/resourcestab/${location.name}`}
                disableRipple
                sx={{ "&:hover": { backgroundColor: "#DEE5ED !important"}, height: "100%" }}
            >
                <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: 200 }}>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flexGrow: 1, height: "100%" }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: 20, height: "100%", textAlign: "center" }}>
                                {location.name}
                            </Typography>
                        </div>
                        {selectedDay !== "All" && (() => {
                            const { displayDay, displayHours } = getDisplayDayAndHours(selectedDay, location);
                            return (
                                <Typography variant="subtitle1" sx={{ fontSize: 20 }}>
                                    {displayDay}: {displayHours}
                                </Typography>
                            );
                        })()}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {location.type === "Food Pantry" ? (
                            <LocalDiningOutlinedIcon sx={{ fontSize: 100 }} />
                        ) : location.type === "Shelter" ? (
                            <HomeOutlinedIcon sx={{ fontSize: 100 }} />
                        ) : location.type === "Medical" ? (
                            <MedicalServicesOutlinedIcon sx={{fontSize: 100}}/>
                        ) : location.type === "Financial" ? (
                            <MonetizationOnOutlinedIcon sx={{fontSize: 100}}/>
                        ) : null}
                    </div>
                </CardContent>
            </CardActionArea>
        </StyledCard>
    )
}