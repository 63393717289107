import React from 'react';
import { Box, Typography, Container, Button, Card, Grid2 } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import image from '../images/lthcHQ_Sunny.jpeg';
import { Helmet } from 'react-helmet-async';

const CenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: 'calc(100vh - 64px)',
  width: '100%',
  padding: '0 16px',
  gap: '24px',
});

const HomePage = () => {
  
  return (
    <>
    
    <Helmet>
      <title>LTHC Homeless Services | Centralized Support & Resources</title>
      <meta name="description" content="LTHC Homeless Services provides centralized access to shelter, food, and other assistance. Find up-to-date information on availability and timings." />
      <meta name="keywords" content="LTHC, homeless services, shelter, food assistance, community support" />
    </Helmet>

    <CenteredContainer maxWidth="lg">
      <Box textAlign="center" width="100%">
        <img src={image} alt="LTHC HQ" style={{ width: '100%', height: 'auto', marginBottom: '24px' }} />
        <Typography fontWeight="bold" variant="h3" gutterBottom>
          Welcome!
        </Typography>
        <Button
          component={Link}
          to='/resourcestab/resources'
          sx={{
            backgroundColor: '#ecb81c',
            color: 'black',
            borderRadius: '20px',
            padding: '20px 30px',
            marginBottom: '75px', 
            underline: 'black',
            fontSize: '22px',
            fontWeight: 'bold',
            textTransform: "none"
          }}
        >
          Discover Resources!
        </Button>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          Services
        </Typography>
      </Box>


      <Grid2 container spacing={3} alignItems={"stretch"} width="100%" height="100%" justifyContent="space-evenly" >
        
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "fill", maxWidth: "22%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Housing
              <Typography textAlign="left" margin={1.5}>
                Providing immediate shelter as well as long-term stay to assist working or finding a permanent home.
              </Typography>
            </Typography>

          </Card>

        
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "fill", maxWidth: "22%" }}>

            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Food
              <Typography textAlign="left" margin={1.5}>
                Options include meal assistance such as food pantries or soup kitchens for everyday essentials. Culinary programs are also available to learn simple cooking skills.
              </Typography>
              
            </Typography>

          </Card>

        
        
          <Card sx={{ display:"inline", backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "fill", maxWidth: "22%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Financial
              <Typography textAlign="left" margin={1.5}>
                Assistance with your financial goals such as setting up a bank account or understanding any aspect of lending.
              </Typography>
             
            </Typography>

          </Card>

          <Card sx={{ display:"inline", backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "fill", maxWidth: "22%" }}>

            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Medical
              <Typography textAlign="left" margin={1.5}>
                For connecting you with medical care including for immediate illness or regular checkups. There are also people you can talk to if you’re struggling with addiction or mental health.
              </Typography>
              
            </Typography>

          </Card>

      </Grid2>
    </CenteredContainer>
    </>
  );
};

export default HomePage;
